<template>
  <div class="password-recovery"> 
    <b-overlay :show="isLoading">
      <b-container class="login-card-container">
        <b-card class="auth-card" label="Request password">
          
          <div v-if="!successful">
            <h1 class="mb-3">{{$t("passwordRequestTitle")}}</h1>
            <form name="form" @submit.prevent="requestPassword">
              <div class="form-group">
                <label for="email">{{ $t("email") }}</label>
                <input
                  v-model="email"
                  v-validate="'required|min:3|max:50'"
                  type="text"
                  class="form-control"
                  name="email"
                />
                <div v-if="submitted && errors.has('email')" class="alert-danger">
                  {{ errors.first("email") }}
                </div>
              </div>
              <div class="submit-button-container form-group">
                <b-button type="submit" block>{{ $t("sendEmail") }}</b-button>
              </div>
            </form>
          </div>

          <div v-if="successful">
            <h1 class="mb-3">{{$t("passwordSuccessTitle")}}</h1>
            <p>{{$t("passwordSuccessDescription")}}</p>
            <div class="submit-button-container form-group">
              <router-link to="/authentication"><b-button type="submit" block> {{ $t("passwordSuccessButton") }}</b-button></router-link>
            </div>
          </div>
        </b-card>
      </b-container>
    </b-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      submitted: false,
      successful: false,
      isLoading: false,
      email: ""
    };
  },
  methods: {
    requestPassword() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.isLoading = false;
          return;
        }
        if (this.email) {
          this.successful = true
        }
      });
    }
  }
};
</script>
<style lang="scss">

</style>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);
.password-recovery {
  flex-grow: 1;
}
.submit-button-container {
  padding-top: 10px;
}
.auth-card {
  max-width: 25rem;
  margin: 0 auto;
  vertical-align: center;
}
.login-card-container {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 75vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}
.link-privacy {
  color: $color-secondary;
}
</style>